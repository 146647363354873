<template>
  <el-drawer class="setting-body" title="Cài đặt" :visible="visible" @close="$emit('close')">
    <div class="config-wrapper">
      <div class="config-item config-time">
        <div class="config-time-top">
          <span>Kích hoạt <el-tooltip content="Chỉ có thể làm bài sau khi đã kích hoạt. Vui lòng kiểm tra kỹ câu hỏi, đáp án (đặc biệt là đáp án đúng) trước khi kích hoạt bài test." placement="top" effect="light">
            <i class="el-icon-info"></i></el-tooltip>
          </span>
          <el-switch
            @change="(value) => updateSetting('is_active', value)"
            v-model="isActive"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </div>
      </div>
      <div class="config-item config-time">
        <div class="config-time-top">
          <span>Giới hạn thời gian làm bài (phút) <el-tooltip content="Nếu bạn nhập 60 thì người làm bài chỉ được làm bài trong vòng 60 phút" placement="top" effect="light">
            <i class="el-icon-info"></i></el-tooltip>
          </span>
          <el-switch
            @change="(value) => updateSetting('timeLimit', value)"
            v-model="timeLimit"
            active-color="#13ce66"
            inactive-color="#C0CCDA"
          >
          </el-switch>
        </div>
        <div class="config-time-input" v-if="timeLimit">
          <el-input-number
            v-model="timeLimitValue"
            :min="5"
          ></el-input-number>
        </div>
        <el-button v-if="timeLimit" type="primary" size="small" class="config-answers-btn" @click="onConfigTimeValueDone">
          Xong
        </el-button>
      </div>
      <div class="config-item config-answers">
        <span class="config-answers-input">Nhập đáp án đúng<i class="el-icon-info"></i></span>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Nhập các án đúng"
          v-model="answers">
        </el-input>
        <el-button v-if="answers" type="primary" size="small" class="config-answers-btn" @click="onConfigAnswersDone">
          Xong
        </el-button>
      </div>
      <div class="config-item config-answers update-avatar">
        <span class="config-answers-input">Cập nhật hình đại diện</span>
        <div class="preview-avatar">
          <img :src="initAvatar" />
        </div>
        <el-upload
            ref="uploadCover"
            :multiple="false"
            :file-list="fileList"
            :action="`${domain}/api/v1/storage/image/upload`"
            class="upload-demo"
            :on-success="afterUploadSuccess"
            :headers="headers"
            @clearFiles="fileList"
          >
            <el-button size="small" type="default"
              >Click to upload</el-button
            >
          </el-upload>
        <el-button v-if="avatar" type="primary" size="small" class="config-answers-btn" @click="updateAvata">
          Xong
        </el-button>
      </div>
      <div class="config-item config-answers">
        <span class="config-answers-input">Meta Title<i class="el-icon-info"></i></span>
        <el-input v-model="metaTitle" clearable> </el-input>

        <el-button v-if="metaTitle" type="primary" size="small" class="config-answers-btn" @click="updateSetting('metaTitle', metaTitle)">
          Xong
        </el-button>
      </div>
      <div class="config-item config-answers">
        <span class="config-answers-input">Meta Description<i class="el-icon-info"></i></span>
        <el-input 
          type="textarea"
          :rows="4"
          v-model="metaDescription" clearable> </el-input>

        <el-button v-if="metaDescription" type="primary" size="small" class="config-answers-btn" @click="updateSetting('metaDescription', metaDescription)">
          Xong
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { message } from '@/utils/message.js'
const domain = process.env.VUE_APP_API_URL

export default {
  props: ['visible', 'setting', 'shareLink', 'exam'],
  data() {
    return {
      isActive: false,
      drawer: false,
      timeLimit: false,
      timeLimitValue: 60,
      password: false,
      ip: false,
      login: true,
      identification: false,
      deadline: false,
      deadlineValue: new Date(),
      answers: '',
      metaTitle: this.exam.meta_title,
      metaDescription: this.exam.meta_description,
      avatar: null,
      initAvatar: null,
      fileList: [],
      domain: domain,
      headers: { 'Authorization': this.$cookies.get('token') }
    };
  },
  mounted() {
    this.initAvatar = this.exam.avatar;
    this.isActive = this.exam.status === 'new'
  },
  methods: {
    onConfigTimeValueDone() {
      this.updateSetting('timeLimitValue', this.timeLimitValue)
    },
    afterUploadSuccess({url}) {
      this.initAvatar = this.domain + url
      this.avatar =  this.domain + url
      this.fileList = [{name: url.split('/').pop(), url}]
    },
    updateAvata() {
      this.$store.dispatch("builder/updateAvatar", {
        id: this.$route.params.id,
        avatar: this.avatar,
      })
    },
    onConfigAnswersDone(){
      const answers = this.answers.match(/[^\r\n]+/g)

      let questions = []
      let newAnswers = []
      let answerMapper = {
        'A': 0,
        'B': 1,
        'C': 2,
        'D': 3,
      }
      let pageQuestion = this.exam.pages.map(page => page.questions)
      
      pageQuestion.forEach(q => {
        questions.push(...q)
      })

      answers.map((answer, index) => {
        if (questions[index].type === 'SINGLE_ANSWER' || questions[index].type === 'DROP_DOWN') {
          let newCorrectIndex = answerMapper[answer]
          newAnswers.push(questions[index].answers[newCorrectIndex].id)
        }
      })

      this.$store.dispatch("builder/configAnswers", {
        id: this.$route.params.id,
        answers: newAnswers,
      })
    },
    copy(){
        let dummy = document.createElement("textarea")
        document.body.appendChild(dummy);
        dummy.value = `${this.shareLink}`
        dummy.select();
        document.execCommand("copy");
        message('Đã sao chép.', 'success')
        document.body.removeChild(dummy);
      },
    updateSetting(type, value) {
      if(type === 'timeLimit') {
        if(!this.timeLimitValue || this.timeLimitValue < 5) {
          message('Vui lòng nhập thời gian làm bài trước khi kích hoạt đồng hồ đếm ngược', 'success')

        } else {
          this.$store.dispatch("builder/updateSettings", {
            id: this.$route.params.id,
            data: {
              type,
              value,
            },
          });
        }
      } else {
        this.$store.dispatch("builder/updateSettings", {
          id: this.$route.params.id,
          data: {
            type,
            value,
          },
        });
      }
    },
  },
  watch: {
    login() {
      this.identification = !this.login;
    },
    setting: {
      immediate: true,
      handler(s) {
        if (s) {
          this.timeLimit = s.time_limit ? true : false;
          this.timeLimitValue = s.time_limit_value || null;
          this.isActive = s.status === 'new'
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-avatar {
  margin-bottom: 1em ;
}
.update-avatar {
  text-align: center;
}
.config-wrapper {
  overflow-y: scroll !important;
  height: 85vh;
}
.config-answers-btn{
  margin-top: 15px;
}
.config-answers{
  display: flex;
  flex-direction: column;
}
.config-answers-input{
  margin-bottom: 15px;
}
.copy-click{
  cursor: pointer;
  font-size: 20px;
}

.config {
  &-item {
    margin: 1em 0.5em 1em 1em;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: thin solid #ededed;
    padding: 12px 16px;
    color: #606266;
  }
  &-time {
    display: flex;
    flex-direction: column;
  }
  &-time-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-time-input {
    margin-top: 10px;
  }
}
</style>