import qs from 'qs'
import axios from "@/utils/axios"

export const getCategories = (page, sort, keyword) => axios.get('/admin/category/list', {
  params: { page, sort, keyword }, 
  paramsSerializer: params => qs.stringify(params)
})
export const deleteCategory = slug => axios.delete(`/admin/category/${slug}`)
export const getCategoryAsOptions = () => axios.get(`/admin/category/options`)
export const createCategory = data => axios.post(`/admin/category/create`, data)
export const updateCategory = (id, data) => axios.put(`/admin/category/${id}`, data)
export const restoreCategory = (id) => axios.put(`/admin/category/${id}/restore`)
