import axios from "@/utils/axios"

export const getExamData = id => axios.get(`/exam/${id}`)
export const getExamDataPreview = id => axios.get(`/admin/exam/preview/${id}`)
export const getExamSubmission = (id, params) => axios.get(`/exam/${id}/responses`, {params})
export const getExamSubmissionDetail = (id, submisionId) => axios.get(`/exam/${id}/response/${submisionId}`)
export const submit = data => axios.post(`/exam/submit`, data)
export const create = data => axios.post(`/admin/exam/create`, data)
export const getExamsByCategory = (categoryId, params) => axios.get(`/admin/exam/list/by-category?categoryId=${categoryId}`,  {params})
export const deleteExam = (id) => axios.delete(`/admin/exam/${id}`)
export const restoreExam = (id) => axios.put(`/admin/exam/${id}/restore`)
