import {
  submit,
  getExamData,
  getExamSubmission,
  getExamSubmissionDetail,
  create,
  getExamsByCategory,
  deleteExam,
  restoreExam,
  getExamDataPreview
} from '@/api/exam.js'
import { message } from '@/utils/message.js'
import Vue from 'vue'
import app from '@/main'

const state = {
  exam: {},
  examPreview: {},
  time: {},
  loading: true,
  done: false,
  result: {},
  showAnswers: false,
  submissions: [],
  pagination: {
    page: 1,
    lastPage: 1,
    total: 0,
    perPage: 10
  },
  filter: {
    sort: 'desc',
    keyword: null,
    mark: 5,
  },
  exams: []
}

// getters
const getters = {}

// actions
const actions = {
  async getExam({ commit }, id) {
    commit('SET_LOADING', true)
    try {
      const response = await getExamData(id)
      commit('SET_EXAM', response.data)
    } catch ({ response }) {
      if (response.status !== 401) message('Unable to load exam data !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getExamPreview({ commit }, id) {
    commit('SET_LOADING', true)
    try {
      const response = await getExamDataPreview(id)
      commit('SET_EXAM_PREVIEW', response.data)
    } catch ({ response }) {
      if (response.status !== 401) message('Unable to load exam data !', 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async submit({ commit, state }) {
    let valid = true
    state.exam.pages.map((page, pIndex) => {
      page.questions.map((question, qIndex) => {
        if (!question.answer) {
          commit('SET_WARNING_ANSWER', { pIndex, qIndex, status: true })
          valid = false
        } else {
          commit('SET_WARNING_ANSWER', { pIndex, qIndex, status: false })
        }
      })
    })
    if (!valid) return message('Please complete all the questions', 'warning')

    try {
      const { data } = await submit(state.exam)
      commit('SET_RESULT', data)
      commit('SET_DONE', true)
      message('Submission successfully', 'success')
    } catch (e) {
      message(e.message, 'error')
    }
  },

  async getExamSubmission({ commit }, reqData) {
    commit('SET_LOADING', true)
    try {
      const { data } = await getExamSubmission(reqData.id, { ...reqData })
      commit('UPDATE_SUBMISSSIONS', data)
    } catch (e) {
      message(e.message, 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async getExamSubmissionDetail({ commit }, { id, submisionId }) {
    commit('SET_LOADING', true)
    try {
      const { data } = await getExamSubmissionDetail(id, submisionId)
      commit('SET_RESULT', data)
    } catch (e) {
      commit('SET_RESULT', { exam: {}, time: {} })
      message(e.message, 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async create({ commit }, reqData) {
    commit('SET_LOADING', true)
    try {
      const { data } = await create(reqData)
      app.$router.push(`/builder/${data.exam.id}`)
    } catch (e) {
      message(e.message, 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async restoreExam({ commit }, id) {
    commit('UPDATE_LOADING', true)
    try {
      await restoreExam(id)

      message('Restore successfully.', 'success')
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async deleteExam({ commit }, data) {
    commit('UPDATE_LOADING', true)
    try {
      await deleteExam(data.id)

      message('Delete successfully.', 'success')
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },

  async getExamsByCategory({ commit, state }, categoryId) {
    commit('SET_LOADING', true)
    try {
      const { data } = await getExamsByCategory(categoryId, { 
        page: state.pagination.page, 
        keyword: state.filter.keyword,
        sort: state.filter.sort
      })
      commit('SET_EXAMS', data)
    } catch (e) {
      message(e.message, 'error')
    } finally {
      commit('SET_LOADING', false)
    }
  },
}

// mutations

const mutations = {
  SET_EXAM(state, { exam, time }) {
    state.exam = exam
    state.time = time
  },
  SET_EXAM_PREVIEW(state, exam) {
    state.examPreview = exam
  },

  SET_EXAMS(state, {response}) {
    state.exams = response.data
    state.pagination.page = response.current_page
    state.pagination.total = response.total
    state.pagination.lastPage = response.last_page
  },

  SET_LOADING(state, loading) {
    if (loading) {
      state.loading = loading
    } else {
      state.loading = !state.loading
    }
  },

  SET_ANSWER({ exam }, { question, answer, pIndex }) {
    let qIndex = exam.pages[pIndex].questions.findIndex(q => q.id === question.id)
    exam.pages[pIndex].questions[qIndex].answer = answer
  },

  SET_WARNING_ANSWER({ exam }, { pIndex, qIndex, status }) {
    Vue.set(exam.pages[pIndex].questions[qIndex], 'warning', status)
  },

  SET_DONE(state, status) {
    state.done = status
  },

  SET_RESULT(state, { score, correctAnswers, totalQuestion, exam }) {
    state.result.score = score
    state.result.correctAnswers = correctAnswers
    state.result.totalQuestion = totalQuestion
    state.exam = exam
  },

  SET_SHOW_ANSWERS(state, status) {
    state.showAnswers = status
  },

  UPDATE_SUBMISSSIONS(state, { submissions }) {
    state.submissions = submissions.data
    state.pagination.page = submissions.current_page
    state.pagination.total = submissions.total
    state.pagination.lastPage = submissions.last_page
  },

  UPDATE_CURRENT_PAGE(state, page) {
    state.pagination.page = page
  },

  RESET_PAGINATE(state) {
    state.pagination = {
      page: 1,
      lastPage: 1,
      total: 0,
      perPage: 10
    }
  },
  UPDATE_SORT_TYPE(state, {type}){
    state.filter.sort = type
  },
  UPDATE_SEARCH_KEYWORD(state, keyword){
    state.filter.keyword = keyword
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}