<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="35%"
      title="Edit Category"
      :center="true"
      :before-close="handleClose"
    >
      <template>
        <div class="add-form" v-loading="loading">
          <div class="form-item">
            <p class="input-class-name">
              Parent<span class="required">*</span>
            </p>
            <el-select
              v-model="initData.parent_id"
              filterable
              disabled
              reserve-keyword
              placeholder="Please enter a keyword"
              :loading="loading"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <p
              class="error"
              v-for="(item, index) in validate"
              :key="`error-${index}`"
            >
              {{ item[0] }}
            </p>
          </div>
          <div class="form-item">
            <p class="input-class-name">Name<span class="required">*</span></p>
            <el-input
              v-model="initData.name"
              placeholder="Tên..."
              :class="{ 'error-highlight': validate.name }"
            >
            </el-input>
            <p
              class="error"
              v-for="(item, index) in validate"
              :key="`error-${index}`"
            >
              {{ item[0] }}
            </p>
          </div>
          <div class="form-item">
            <p class="input-class-name">Meta title</p>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Enter the title.."
              v-model="initData.meta_title"
            >
            </el-input>
          </div>
          <div class="form-item">
            <p class="input-class-name">Meta description</p>
            <el-input
              type="textarea"
              :rows="3"
              placeholder="Description about category.."
              v-model="initData.meta_description"
            >
            </el-input>
            <div class="form-item">
              <p class="input-class-name">H1 title</p>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Description about category.."
                v-model="initData.h1_title"
              >
              </el-input>
            </div>
            </div>
            <div class="form-item">
              <p class="input-class-name">Short description</p>
              <el-input
                type="textarea"
                :rows="3"
                placeholder="Description about category.."
                v-model="initData.short_description"
              >
              </el-input>
            </div>

          <div class="form-item">
            <p class="input-class-name">Avatar</p>
            <div class="preview-avatar">
              <img :src="initData.avatar" />
            </div>
            <el-upload
                ref="uploadCover"
                :multiple="false"
                :file-list="fileList"
                :action="`${domain}/api/v1/storage/image/upload`"
                class="upload-demo"
                :on-success="afterUploadSuccess"
                :headers="headers"
                @clearFiles="fileList"
              >
                <el-button size="small" type="default"
                  >Click to upload</el-button
                >
              </el-upload>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="default" @click="handleClose" size="small">
            Cancel
          </el-button>
          <el-button type="primary" size="small" @click="onDone">
            Done
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
const domain = process.env.VUE_APP_API_URL

export default {
  props: ["dialogVisible", "category"],
  computed: {
    ...mapState("category", ["loading", "validate", "options", "currentCategory"]),
  },

  async mounted() {
    this.initData = { ...this.category }

    await this.$store.dispatch("category/getCategoryAsOptions");
  },
  data() {
    return {
      name: null,
      parent: 0,
      title: null,
      avatar: null,
      description: null,
      fileList: [],
      domain: domain,
      initData: {},
      headers: { 'Authorization': this.$cookies.get('token') }
    };
  },
  methods: {
    handleClose() {
      this.resetState()
      this.$emit("close-dialog")
    },
    onDone() {
      this.$store.dispatch("category/update", {
        id: this.initData.id,
        name: this.initData.name ,
        meta_title: this.initData.meta_title,
        meta_description: this.initData.meta_description,
        h1_title: this.initData.h1_title,
        short_description: this.initData.short_description,
        avatar: this.avatar
      });
      this.handleClose()
    },
    resetState() {
      this.parent = 0
      this.name = null
      this.title = null
      this.description = null
      this.fileList = []
      this.initData = {}
    },
    afterUploadSuccess({url}) {
      this.initData.avatar = `${domain}${url}`
      this.avatar =  `${domain}${url}`
      this.fileList = [{name: url.split('/').pop(), url}]
    }
  },
};
</script>

<style lang="scss" scoped>
.select {
  &-type {
    display: flex;
    justify-content: space-around;
  }
  &-box {
    cursor: pointer;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
  }
  &-box:hover {
    border: 1px solid #67c23a;
  }
}
.upload-demo {
  margin-top: 1em;
  text-align: center !important;
}
.preview-avatar {
  text-align: center;
}
.required {
  color: red;
}

.input-class {
  &-name {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.custom-checked {
  color: #67c23a;
}

.selected {
  border: 1px solid #67c23a;
}

.type-name {
  font-weight: bold;
  margin-top: 15px;
}
</style>

<style>
.upload-class-avata .el-upload .el-upload-dragger {
  width: 100% !important;
}

.upload-class-avata .el-upload {
  margin-top: 30px;
  width: 100% !important;
}

.error {
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.form-item > .el-select {
  display: flex;
}
</style>