<template>
  <div>
    <div v-if="examPreview">
      
      <div 
        v-if="examPreview.setting && examPreview.setting.time_limit && examPreview.type !== 'mbti' && examPreview.type !== 'pdf'"
        :class="showClockBar ? 'countdown-timer-end' : 'countdown-timer'"
        >
        <Countdown :examType="examPreview.type" :totalMinutes="examPreview.setting.time_limit_value" @time-up="handleTimeUp" /> 
      </div>
      <div class="questions-container">
        <Pdf v-if="examPreview.type === 'pdf'"/>
        <Mbti v-else-if="examPreview.type === 'mbti'"/>
        <Web v-else />
      </div>
    </div>
    <div
      element-loading-text="Đang xử lý..."
      class="exam-loading"
      v-else
      v-loading="loading"
    ></div>
  </div>
</template>

<script>
import Web from "@/components/exam/Web.vue";
import Pdf from "@/components/exam/Pdf.vue";
import Mbti from "@/components/exam/Mbti.vue";
import Countdown from '@/components/timer/Countdown.vue'
import { mapState } from "vuex";
import { message } from '@/utils/message.js'

export default {
  components: { Web, Pdf, Mbti, Countdown },

  computed: {
    ...mapState("auth", ["user", "loading"]),
    ...mapState("exam", ["examPreview", "done", "showAnswers", "time"]),
    exam(){      
      return this.examPreview;
    }
  },
  mounted() {
    if (!this.user.email) {
      this.$store.commit(
        "auth/UPDATE_NEXT_STEP",
        `${window.location.pathname}${window.location.search}`
      );
      this.$router.push({ name: "Login" });
    } else this.$store.dispatch("exam/getExamPreview", this.$route.params.id);
  },
  data() {
    return {
      currentPage: 0,
      countDown: "60:00s",
      showClockBar: false
    };
  },
  methods: {
    next() {
      this.currentPage = this.currentPage + 1
    },
    back() {
      this.currentPage = this.currentPage - 1
    },
    onAnswer({ question, answer }) {
      this.$store.commit("exam/SET_ANSWER", {
        question,
        answer,
        pIndex: this.currentPage,
      })
    },
    showDetails() {
      this.currentPage = 0;
      this.$store.commit("exam/SET_SHOW_ANSWERS", true)
    },
    oneTimeEnd() {
      this.$store.dispatch("exam/submit")
    },

    handleTimeUp() {
      message("Time's up", 'success')

    }
  },
};
</script>
<style lang="scss" scoped>

.exam-loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-timer {
  position: fixed;
  z-index: 8 !important;
  /* width: 100vw; */
  /* text-align: center; */
  padding-bottom: 9px;
  bottom: 0;

}

.countdown-timer-end {
  position: fixed;
  z-index: 7 !important;
  padding: 0.5em;
  bottom: 0;
  background: white !important;
  width: 100vw !important;
  box-shadow: 0px 1px 7px 0px #5b615c57 !important;
  .clock {
    margin-left: 0;
  }
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #606266;
}

.point {
  margin-top: 10%;
  font-size: 30px;
  color: #67c23a;
  text-align: center;
}

.page {
  width: 70%;
  box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
  background-color: #fff;
  min-height: 95vh;
  margin: 2vh 0 2vh 0;
  padding: 0 2vw 0 2vw;

  &-title {
    text-align: center;
    width: 95%;
  }

  &-description {
    font-style: italic;
  }

  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .question {
    padding-top: 20px;
  }

  .timer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: black;
    i {
      font-size: 20px;
    }
  }

  .countdown {
    margin-top: 3px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .page {
    width: 100%;
    min-height: 100vh;
    margin: unset;
  }
}
</style>