<template>
    <section>
      <div v-loading="loading">
        <Header />
        <section class="main">
          <Menu />
          <div class="workspace">
            <div class="tools">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :current-page="pagination.page"
                :page-size="pagination.perPage"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
  
              <div class="tools-right">
                <div class="filters">
                  <el-dropdown class="active filter" @command="sortByStatus">
                    <el-button>
                      Trạng thái<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="status in feedbackStatuses"
                        :key="status.value"
                        icon="el-icon-sort-up"
                        :command="{ type: status.value, name: status.name }"
                      >
                        {{ status.name }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>

                  <el-dropdown class="active filter" @command="sortByType">
                    <el-button>
                      Phân loại <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="feedback in feedbackTypes"
                        :key="feedback.value"
                        icon="el-icon-sort-up"
                        :command="{ type: feedback.value, name: feedback.name }"
                      >
                        {{ feedback.name }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <div class="class-boxes" v-if="feedbacks.total > 0">
              <FeedbackItem
                @updateStatus="updateStatus"
                @openConfirmBox="openConfirmBox"
                v-for="(feedback, index) in feedbacks.data"
                :feedback="feedback"
                :key="`class-${index}`"
              />
            </div>
            <div class="empty" v-else>
              <div class="empty-wrapper">
                <i class="el-icon-folder-opened empty-icon"></i>
                <p>
                  Chưa có dữ liệu 
                </p>
              </div>
            </div>
          </div>
        </section>
        <ConfirmPopup
          :open="openConfirmPopup"
          :payload="currentFeedback"
          @confirm="handleUpdateStatus(currentFeedback.id, currentFeedback.status)"
          @closeConfirmBox="openConfirmPopup = false"
        > 
          <p v-if="currentFeedback.status != 'resolved'" class="feedback-content-confirm">
            Lưu ý: Bạn hãy xử lý phản hồi <strong>#{{ currentFeedback.id }}</strong> trước khi hoàn tất nhé !
          </p>
          <p v-else class="feedback-content-confirm">
             Bạn muốn đánh dấu phản hồi <strong>#{{ currentFeedback.id }}</strong> là chưa xử lý ?
          </p>
        </ConfirmPopup>

        <!-- <UpdateStatusDialog
          :open="openEditDialog"
          @updateFeedbackStatus="handleUpdateStatus"
          :feedback="currentFeedback"
          @closeEditBox="openEditDialog = false"
        >      
        </UpdateStatusDialog> -->
      </div>
    </section>
  </template>
  
  <script>
  import { mapState } from "vuex"
  import Menu from "@/components/commons/Menu.vue"
  import Header from "@/components/commons/Header.vue"
  import FeedbackItem from "@/components/feedbacks/Item.vue"
  import ConfirmPopup from '@/components/feedbacks/ConfirmPopup.vue'

  const feedbackTypes = [
    { name: 'Tất cả', value: 'all' },
    // { name: 'Feedback', value: 'feedback' },
    { name: 'Request', value: 'request' },
    { name: 'Report', value: 'report' },
    // { name: 'Other', value: 'other' }
  ];

  const feedbackStatuses = [
    { name: 'Tất cả', value: 'all' },
    { name: 'Chưa xử lý', value: 'new' },
    { name: 'Đã xử lý', value: 'resolved' }
  ];
  export default {
    components: {
      Menu,
      Header,
      FeedbackItem,
      ConfirmPopup,
    },
    computed: {
      ...mapState("feedback", [
        "feedbacks",
        "loading",
        "sort",
        "pagination",
      ]),

    },
    beforeMount() {
      this.$store.commit("exam/RESET_PAGINATE")
    },
    mounted() {
      this.$store.dispatch("feedback/getAll")
    },
    data() {
      return {
        keyword: null,
        openEditDialog: false,
        currentFeedback: {},
        feedbackTypes,
        feedbackStatuses,
        openConfirmPopup: false,
      };
    },
    methods: {
      async handleCurrentChange(page) {
        await this.$store.commit("feedback/UPDATE_CURRENT_PAGE", page)
        this.$store.dispatch("feedback/getAll")
      },

      async sortByType(data){
        await this.$store.commit("feedback/UPDATE_FILTER_TYPE", { field: "type", value: data.type })
  
        this.$store.dispatch("feedback/getAll")
      },

      async sortByStatus(data) {
        await this.$store.commit("feedback/UPDATE_FILTER_TYPE", { field: "status", value: data.type })
  
        this.$store.dispatch("feedback/getAll")
      },

      async handleUpdateStatus(id, value) {
        const data = !(value === 'resolved');
        this.$store.dispatch('feedback/updateStatus', { id, data})
        this.openConfirmPopup = false
      },
      openConfirmBox({ feedback }) {
        this.currentFeedback = feedback

        this.openConfirmPopup = true
      },
      updateStatus({ feedback }) {
        this.currentFeedback = feedback
        this.openEditDialog = true
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .feedback-content-confirm {
    text-align: center;
    padding: none;
    color: #e4974a;
    line-height: 2em;
    font-size: 14px;
    word-break: break-all;
  }
  .main {
    min-height: 95vh;
    display: grid;
    grid-template-columns: 1fr 20fr;
  }
  .empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
  
    &-icon {
      font-size: 50px;
      color: #c0c4cc;
      margin-bottom: 20px;
    }
  
    &-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #5e6d82;
    }
  }
  
  .tools {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    &-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
  
  .create-link {
    color: #409eff;
    cursor: pointer;
  }
  
  .filters {
    display: flex;
  }
  
  .filter {
    margin-left: 10px;
  }
  
  .ic {
    font-size: 20px;
    margin-right: 10px;
    color: #c0c4cc;
  }
  
  .workspace {
    padding: 15px;
    padding-top: 85px;
    width: calc(100vw - 120px);
    min-height: 30vh;
    padding-left: 80px;
  }
  
  .class-boxes {
    margin-bottom: 15px;
    min-height: 30vh;
  }
  </style>