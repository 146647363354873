<template>
  <div class="class-box">
    <img :src="category.avatar" class="class-icon" />
    <div class="class-name" v-if="category.parent_id > 0">  
      <h3>{{ category.name }}</h3>&nbsp;({{ category.parent.name }})
    </div> 
    <h3 v-else class="class-name">{{ category.name }}</h3>
    <p class="class-info-item">
      <router-link :to="`/category/${category.id}/exams`" class="link">
        <i class="el-icon-finished ic"></i>
         Danh sách đề thi <b class="highlight">{{ category.exams_count }}</b>
      </router-link>
      
    </p>
    <div class="class-info-item">
      <el-dropdown @command="command">
        <el-button type="primary">
        Thao tác<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="{ type: 'newExam', data: { category } }">
            <i class="el-icon-baseball"></i> Tạo đề thi
          </el-dropdown-item>
          <el-dropdown-item :command="{ type: 'edit', data: { category } }"> <i class="el-icon-edit"></i> Sửa </el-dropdown-item>
          <el-dropdown-item v-if="!category.deleted_at" :command="{ type: 'delete', data: { category } }">
            <i class="el-icon-delete"></i> Xoá
          </el-dropdown-item>
          <el-dropdown-item v-else :command="{ type: 'restore', data: { category } }">
            <i class="el-icon-refresh-left"></i> Khôi phục
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  props: ["category"],
  data() {
    return {
      inviting: {},
      openInvite: false,
    }
  },
  methods: {
    command({ type, data }) {
      if (type === "edit") this.$emit("openEdit", data)
      if (type === "newPost") this.$router.push(`/post/create?categoryId=${this.category.id}`)
      if (type === "newExam") this.$emit("toggleNewExam", data)
      if (type === "delete") this.$emit("openDeleteBox", data)
      if (type === "restore") this.$emit("restoreCategory", data)
    },
  },
}
</script>
<style lang="scss" scoped>
.class-info-item {
  justify-content: center;
}
.link {
  text-decoration: none;
  color: black;
}

.link:hover {
  text-decoration: none;
  color: #44ce6f;
}

.class {
  &-icon {
    width: 100px;
    height: auto;
  }
  &-box {
    border: none;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    background-color: #fff;
    background-clip: border-box;
    border-radius: 0.25rem;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 4fr 1fr 1fr;
    margin-top: 15px;
    align-items: center;
    justify-items: center;
  }

  &-box:hover {
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
    transition: 0.3s;
  }
}

.class-name{
  display: flex;
  align-items: center;
}

.ic {
  font-size: 20px;
  margin-right: 10px;
}
.highlight {
  font-weight: bold;
  color: #44ce6f;
}
</style>