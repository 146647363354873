<template>
    <div>
      <div class="container" v-if="exam.pages && exam.pages.length > 0">
        <div class="page">
          <el-alert
            title="Bạn đang ở chế độ xem trước đề thi dành cho quản trị viên!"
            type="warning"
            center
            show-icon>
          </el-alert>
          <el-breadcrumb class="exam-breadcrumb" separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }"> Trang chủ </el-breadcrumb-item>
              <el-breadcrumb-item> 
              <span style="cursor:pointer">{{ exam.category.name }}</span> </el-breadcrumb-item>

          </el-breadcrumb>

          <section class="page-header">
            <h1 class="page-title">{{ exam.name }}</h1>
          </section>
          <section>
            <div class="page-description" v-html="exam.pages[currentPage].note || null"></div>
            <strong> Tổng số câu hỏi: {{ totalQuestions }} </strong>
            <div class="questions">
              <div
                v-for="(question, index) in exam.pages[currentPage].questions"
                :key="`question-${index}`"
                class="question"
              >
                <Single
                  v-if="question.type === 'SINGLE_ANSWER'"
                  :question="question"
                  @onAnswer="onAnswer"
                  :showAnswers="showAnswers"
                />
                <TextQuestion
                  v-if="question.type === 'TEXT'"
                  :question="question"
                  @onAnswer="onAnswer"
                />
                <Select
                  v-if="question.type === 'DROP_DOWN'"
                  :question="question"
                  @onAnswer="onAnswer"
                  :showAnswers="showAnswers"
                />
              </div>
            </div>
          </section>
          <ExamFooter
            :pages="exam.pages"
            :currentPage.sync="currentPage"
            :showAnswers="showAnswers"
            @next="next"
            @back="back"
          />
        </div>
      </div>
      <div
        element-loading-text="Đang xử lý..."
        class="exam-loading"
        v-else
        v-loading="loading"
      ></div>
    </div>
  </template>
  <script>
  import { mapState } from "vuex"
  import Single from "@/components/questions/Single"
  import TextQuestion from "@/components/questions/TextQuestion"
  import Select from "@/components/questions/Select"
  import ExamFooter from "@/components/exam/commons/ExamFooter"
  
  export default {
  props: {
  },
    name: 'ExamWeb',
    components: {
      TextQuestion,
      Single,
      Select,
      ExamFooter,
    },
    computed: {
      ...mapState("exam", ["examPreview", "examCategory", "done", "time", "loading", 'checking']),
      exam() {
        return this.examPreview
      },
      totalQuestions() {
        return this.exam ? this.exam.pages.reduce((total, page) => {
          return total + (page.questions ? page.questions.length : 0);
        }, 0) : 0;
      }
    },
    data() {
      return {
        currentPage: 0,
        countDown: "60:00s",
        showAnswers: true,
        examMeta: {
          meta_title: 'Làm bài',
          meta_description: 'Làm bài trắc nghiệm'
        }
      };
    },
    updated () {
      window.scrollTo(0,0);
    },
    methods: {
      next() {
        this.currentPage = this.currentPage + 1
      },
      back() {
        this.currentPage = this.currentPage - 1
      },
      onAnswer({ question, answer }) {
        this.$store.commit("exam/SET_ANSWER", {
          question,
          answer,
          pIndex: this.currentPage,
        })
      },
      showDetails() {
        this.currentPage = 0;
        this.$store.commit("exam/SET_SHOW_ANSWERS", true)
      },
    },
  }
  </script>
  <style lang="scss" scoped>
  .exam-breadcrumb {
    margin-bottom: 0.5em !important;
    margin-top: 1em;
  }
  .warning{
    font-size: 16px !important;
  }
  .go-home-btn{
    font-size: 1.5em;
    cursor: pointer;
  }
  .page-description{
    padding-bottom: 20px;
  }
  .exam-loading{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606266;
  }
  
  .point {
    margin-top: 10%;
    font-size: 30px;
    color: #67c23a;
    text-align: center;
  }
  
  .page {
    width: 70%;
    box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
    background-color: #fff;
    min-height: 95vh;
    margin: 4vh 0 8vh 0;
    padding: 4vh 2vw 2vh 2vw;
  
    &-title {
      text-align: center;
      width: 95%;
    }
  
    &-description {
      font-style: italic;
    }
  
    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .timer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: black;
      i {
        font-size: 20px;
      }
    }
  
    .countdown {
      margin-top: 3px;
      margin-left: 5px;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .page {
      width: 100%;
      min-height: 100vh;
      padding: 5%;
    }
    .page-header{
      align-items: baseline;
    }
  }
  </style>
  <style>
  table td,
  table th {
    min-width: 1em;
    border: 1px solid #ddd;
    padding: 15px 15px;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  p {
    line-height: 25px;
  }
  
  table {
    width: 100%;
  }

img {
  max-width: 100% !important;
}
</style>
