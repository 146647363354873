<template>
  <section>
    <div  v-loading="loading">
      <Header />
      <section class="main">
        <Menu />
        <div class="workspace">
          <div class="tools">
            <el-pagination
              background
              layout="prev, pager, next"
              :total="pagination.total"
              :current-page="pagination.page"
              :page-size="10"
              @current-change="handleCurrentChange"
            >
            </el-pagination>

            <div class="tools-right">
              <div class="search">
                <el-input v-model="keyword" placeholder="Nhập từ khoá">
                  <i
                    v-if="keyword"
                    class="el-icon-circle-close el-input__icon"
                    slot="suffix"
                    @click="keyword = null"
                  >
                  </i>
                  <i v-else class="el-icon-search el-input__icon" slot="suffix">
                  </i>
                </el-input>
              </div>
              <div class="filters">
                <el-dropdown class="active filter" @command="sortName">
                  <el-button>
                    Tiêu đề<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-sort-up"
                      :command="{ type: 'asc', name: 'Tăng dần' }"
                    >
                     Tăng dần
                    </el-dropdown-item>
                    <el-dropdown-item
                      class="el-icon-sort-down"
                      :command="{ type: 'desc', name: 'Giảm dần' }"
                    >
                    Giảm dần
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  class="filter"
                  plain
                  @click="toggleNewExam"
                >
                Tạo đề thi mới
                </el-button>
              </div>
            </div>
          </div>
          <div class="class-boxes" v-if="exams.length > 0">
            <ExamItem
              @open-dialog="newExam = true"
              @shareLink="shareLink"
              @openDeleteBox="openDeleteBox"
              @restoreExam="restoreExam"
              v-for="(exam, index) in exams"
              :exam="exam"
              :key="`class-${index}`"
            />
          </div>
          <div class="empty" v-else>
            <div class="empty-wrapper">
              <i class="el-icon-folder-opened empty-icon"></i>
              <p>
                You don't have any exams. Please
                <b class="create-link" @click="newExam = true">create new.</b>
              </p>
            </div>
          </div>
        </div>
      </section>
      <NewExam
        :openNewExam="openNewExam"
        @toggleNewExam="toggleNewExam"
      />
      <DeletePopup
        :open="openDelete"
        :payload="selectedDelete"
        @delete="deleteExam"
        @closeDeleteBox="openDelete = false"
      >      
        <span>Are you sure to delete this exam ?</span>
      </DeletePopup>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import Menu from "@/components/commons/Menu.vue"
import Header from "@/components/commons/Header.vue"
import ExamItem from "@/components/exam/ExamItem.vue"
import NewExam from "@/components/class/NewExam.vue"
import DeletePopup from "@/components/commons/DeletePopup.vue"

export default {
  components: {
    Menu,
    Header,
    ExamItem,
    NewExam,
    DeletePopup
  },
  computed: {
    ...mapState("exam", [
      "exams",
      "loading",
      "sort",
      "pagination",
    ]),
  },
  beforeMount() {
    this.$store.commit("exam/RESET_PAGINATE")
  },
  mounted() {
    this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
  },
  data() {
    return {
      keyword: null,
      delayTimer: null,
      openShareBox: false,
      selected: null,
      openDelete: false,
      selectedDelete: {},
      openNewExam: false
    };
  },
  methods: {
    toggleNewExam() {
      this.openNewExam = !this.openNewExam
    },
    async handleCurrentChange(page) {
      await this.$store.commit("exam/UPDATE_CURRENT_PAGE", page)
      this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id, page)
    },
    async sortName(data) {
      await this.$store.commit("exam/UPDATE_SORT_TYPE", data)

      this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
    },
    shareLink({ exam }) {
      this.selected = exam;
      this.openShareBox = true
    },
    openDeleteBox({ exam }) {
      this.selectedDelete = exam
      this.openDelete = true
    },
    async deleteExam(exam) {
      await this.$store.dispatch("exam/deleteExam", {
        id: exam.id
      }).then(() => {
        this.openDelete = false

        this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
      })

    },
    async restoreExam(data) {
      await this.$store.dispatch("exam/restoreExam", data.exam.id).then(() => {
        this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
      })
    },
  },
  watch: {
    async keyword(k) {
      if (k) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          await this.$store.commit("exam/UPDATE_SEARCH_KEYWORD", k)
          this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
        }, 900)
      } else {
        await this.$store.commit("exam/UPDATE_SEARCH_KEYWORD", null)
        this.$store.dispatch("exam/getExamsByCategory", this.$route.params.id)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 95vh;
  display: grid;
  grid-template-columns: 1fr 20fr;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  &-icon {
    font-size: 50px;
    color: #c0c4cc;
    margin-bottom: 20px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #5e6d82;
  }
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.create-link {
  color: #409eff;
  cursor: pointer;
}

.filters {
  display: flex;
}

.filter {
  margin-left: 10px;
}

.ic {
  font-size: 20px;
  margin-right: 10px;
  color: #c0c4cc;
}

.workspace {
  padding: 15px;
  padding-top: 85px;
  width: calc(100vw - 120px);
  min-height: 30vh;
  padding-left: 80px;
}

.class-boxes {
  margin-bottom: 15px;
  min-height: 30vh;
}
</style>