<template>
  <div class="container">
    <div class="pdf-viewer" :key="pdfSrc">

      <el-alert
        title="Bạn đang ở chế độ xem trước đề thi dành cho quản trị viên!"
        type="warning"
        center
        class="warning-preview"
        show-icon>
      </el-alert>
      <client-only placeholder="Đang tải tài liệu, vui lòng đợi chút xíu...">
        <pdf class="first-pdf" :src="pdfSrc" :page="1" @num-pages="pdfPageCount = $event"></pdf>
        <div v-if="pdfPageCount > 1">
            <div v-for="(pageNum, index) in pdfPageCount " :key="index">
                <pdf class="ysecond-pdf" :src="pdfSrc" :page="pageNum" v-if="pageNum > 1"></pdf>
            </div>
        </div>
    </client-only>

    </div>
    <div class="answer-wrapper" v-loading="checking">
      <div class="info">
        <h2 class="exam-name">
          {{ exam.name }}
        </h2>
        <div v-html="exam.pages[0].note"></div>

        <el-breadcrumb class="exam-breadcrumb" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }"> Trang chủ </el-breadcrumb-item>
          <el-breadcrumb-item> <span style="cursor:pointer">{{ exam.category.name }}</span> </el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <template v-if="started">
        <div class="answer-sheet">
          <div class="answer-sheet-note" v-if="isMobile()">
            Vui lòng trả lời bằng cách nhấn vào các câu hỏi phía bên dưới và
            chọn đáp án đúng
          </div>
          <div class="answer-sheet-note" v-else>
            Vui lòng trả lời bằng cách di chuột vào các câu hỏi phía bên dưới và
            chọn đáp án đúng
          </div>
          <div v-if="exam.pages.length > 0">
            <div
              v-for="(page) in exam.pages"
              :key="`page-${page.id}`"
              class="answers"
            >
              <div
                v-for="question in page.questions"
                :key="`question-${question.id}`"
              >
                <PdfQuestion :question="question" :showAnswers="showAnswers" />
              </div>
            </div>
          </div>
          <div class="mobile-submission">
            <div v-if="result.score !== undefined" class="mobile-results">
              <p>
                Với <b class="bold">{{ result.correctAnswers }}</b> câu trả lời
                đúng. Số điểm của bạn là <b class="bold">{{ result.score }}</b>
              </p>
              <div>
                <el-button
                  type="primary"
                  class="my-btn"
                  slot="reference"
                  icon="el-icon-view"
                  v-if="exam.pages[0].answers_file && !isShowingAnswers"
                  @click="viewAnswerFile"
                >
                  Xem lời giải
                </el-button>
                <el-button
                  type="primary"
                  slot="reference"
                  class="my-btn"
                  icon="el-icon-position"
                  @click="redirect(`/danh-sach-bai-tap/${examCategory.slug}`)"
                  plain
                >
                  Làm đề khác
                </el-button>
              </div>
            </div>
    
            <el-button
              type="primary"
              slot="reference"
              icon="el-icon-check"
              class="my-btn"
              disabled
            >
              Nộp bài
            </el-button>
      
          </div>
        </div>
        <div class="timer" v-if="result.score !== undefined">
          <div class="timer-note">
            Với <b class="bold">{{ result.correctAnswers }}</b> câu trả lời
            đúng. Số điểm của bạn là
          </div>
          <div class="timer-countdown">
            {{ result.score }}
          </div>
          <div>
            <el-button
              type="primary"
              class="my-btn"
              slot="reference"
              icon="el-icon-view"
              v-if="exam.pages[0].answers_file && !isShowingAnswers"
              @click="viewAnswerFile"
            >
              Xem lời giải
            </el-button>
            <el-button
              type="primary"
              slot="reference"
              class="my-btn"
              icon="el-icon-position"
              @click="redirect(`/danh-sach-bai-tap/${examCategory.slug}`)"
              plain
            >
              Làm đề khác
            </el-button>
          </div>
        </div>
        <div class="timer" v-else>
          <div v-if="countDown">
            <div class="timer-note" v-if="countDown > 5">
              Thời gian làm bài còn lại (phút)
            </div>
            <div class="timer-note" v-else>
              Sắp hết thời gian, hệ thống sẽ tự động nộp bài khi hết giờ
            </div>
            <div
              class="timer-countdown"
              :class="{ 'out-of-time': countDown <= 5 }"
            >
              {{ countDown }}
            </div>
          </div>
          <div>
            <el-button
              type="primary"
              slot="reference"
              icon="el-icon-check"
              class="my-btn"
              disabled
            >
              Nộp bài
            </el-button>
          </div>
        </div>
      </template>
      <div v-else class="starting">
        <el-button type="primary" @click="start">Bắt đầu làm bài</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapState } from "vuex";
import PdfQuestion from "@/components/questions/PdfQuestion";
import pdf from 'vue-pdf'
import { message } from '@/utils/message.js'

export default {
  created () {
  },
  name: 'ExamPdf',

  components: { PdfQuestion, pdf },
  data() {
    return {
      countDown: 0,
      answerFile: null,
      started: true,
      pdfPageCount : 0,
      pdfSrc: '',
      summited: false,
      isShowingAnswers: false,
      showAnswers: true,
    };
  },
  mounted() {
    this.pdfSrc = this.exam.pages[0].questions_file
    console.log(this.pdfSrc)

    if(this.exam.setting && this.exam.setting.time_limit) {
      this.start()
    }
  },
  computed: {
    ...mapState("exam", [
      "examPreview",
      "done",
      "time",
      "loading",
      "checking",
      "result",
      "examCategory",
    ]),

    exam() {
      return this.examPreview;
    }
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    start() {
      this.started = true;
      this.countDown = this.exam.setting && this.exam.setting.time_limit ? this.exam.setting.time_limit_value : false;
      this.countDownTimer();
    },
    viewAnswerFile() {
      this.isShowingAnswers = true
      this.pdfPageCount = 0
      this.pdfSrc = this.exam.pages[0].answers_file;
      this.$forceUpdate();
    },
    redirect(url) {
      location.href = url;
    },
    onAnswer(command) {
      this.$store.commit("exam/SET_ANSWER", {
        question: command.question,
        answer: command.answer.name,
        pIndex: command.pageIndex,
      });
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000 * 60);
      } else {
        if (!this.summited) {
          message("Time's up", 'success')
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-preview {
  margin-top: 1em;
}
.pdf-viewer{
  width: 80%;
  padding-bottom: 30vh;
}
.mobile-submission {
  display: none;
}

.starting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bold {
  font-weight: bold;
  color: #67c23a;
}
.selected {
  font-weight: bold;
  color: #67c23a;
}
.my-btn {
  margin-top: 5px;
}
.info {
  padding-left: 20px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.is-wrong {
  color: #f56c6c;
}
.is-correct {
  color: #67c23a;
}
.out-of-time {
  margin: 0 auto;
  border-color: #f56c6c !important;
}
.timer-countdown {
  margin: 0 auto;
}
.bold {
  font-weight: bold;
}
.italic {
  font-style: italic;
}
.container {
  color: #606266;
}
.timer {
  display: grid;
  justify-items: center;
  align-content: space-between;

  &-note {
    margin-bottom: 20px;
  }

  &-countdown {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 2px green;
    border-radius: 100%;
    padding: 30px;
    width: 80px;
    height: 80px;
    text-align: center;
    font-weight: bold;
  }
}
.answer-sheet {
  text-align: center;

  &-note {
    margin-bottom: 20px;
  }
}
.answers {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 10px;
}
.exam-name {
  font-size: 16px;
  padding: unset;
  margin: unset;
}
.answer-wrapper {
  width: 100%;
  color: #606266;
  position: fixed;
  bottom: 0px;

  max-width: 100%;
  min-height: 25vh;
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
}
.custom-el-dropdown {
  max-width: 40px;
}

.exam-breadcrumb {
  margin-top: 2em;
}
@media only screen and (max-width: 768px) {
  .pdf-viewer{
    width: 100%;
    padding-left: unset
  }
  .info {
    display: none;
  }
  .timer {
    display: none;
  }
  .starting {
    width: 100vw;
  }
  .answer-sheet {
    max-width: 100vw;
  }
  .mobile-submission {
    display: flex;
    justify-content: center;
  }
  .answers {
    padding: 5px;
    grid-gap: 5px;
    max-width: 100vw;
  }
}
</style>