<template>
   
    <div v-if="examType !== 'pdf' " class="clock">
      <div class="icon-clock">
        <i class="el-icon-alarm-clock" ></i>
      </div>
      <span class="time-text">{{ formattedTime }}</span>
    </div>
    <div v-else class="clock-pdf-exam">
      <div class="icon-clock">
        <i class="el-icon-alarm-clock" ></i>
      </div>
      <span class="time-text">{{ formattedTime }}</span>
    </div>
  
  </template>
  
  <script>
  import { mapState } from "vuex";

  export default {
  watch: {
  },
    props: {
        totalMinutes: {
        type: Number,
        required: true
      }, 
      examType: {
        type: String,
      }
    },
    data() {
      return {
        timeRemaining: 0,
        intervalId: null
      };
    },
    computed: {
      ...mapState("exam", ["exam", "loading"]),
      formattedTime() {
        const hours = Math.floor(this.timeRemaining / 3600);
        const minutes = Math.floor((this.timeRemaining % 3600) / 60);
        const seconds = this.timeRemaining % 60;
        
        return `${hours < 10 ? '0' + hours : hours}:${
                 minutes < 10 ? '0' + minutes : minutes}:${
                 seconds < 10 ? '0' + seconds : seconds}`;
      }
    },
    mounted() {
      this.startCountdown();
    },
    methods: {
      startCountdown() {
        // Convert total minutes to seconds
        this.timeRemaining = this.totalMinutes * 60;
        this.intervalId = setInterval(() => {
          if (this.timeRemaining > 0) {
            this.timeRemaining--;
          } else {
            clearInterval(this.intervalId);
            this.timeUp();
          }
        }, 1000);
      },

      timeUp() {
        this.$emit('time-up', "Time's up"); // Emit event to parent
        // alert("Time's up!");
      }
    },
    beforeDestroy() {
      // Clear interval to prevent memory leaks
      clearInterval(this.intervalId);
    }
  };
</script>
  
<style>
.clock {
  padding: 0.5em 2em;
  padding: 0.5em 2em;
    /* border: 0.2px solid; */
  /* border-radius: 2em; */
  z-index: 9 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  margin-left: 13vw;
  color: rgb(28 145 18);
}
.clock-pdf-exam {
  padding: 0.5em 2em;
  /* border: 0.2px solid; */
  /* border-radius: 2em; */
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
  font-size: 8px;
  margin-left: 7vw;
  color: rgb(28 145 18);
}
.icon-clock {
  font-size: 20px;
  margin-right: 3px;
  font-weight: bold;
}
.time-text {
    font-weight: bold;
    font-size: 14px;
}
@media only screen and (max-width: 600px) {
  .clock {
    margin-left: 4vw;
  }
}
</style>
