<template>
    <section class="header">
        <div class="header-left">
            <router-link to="/">
                <img src="@/assets/lg.png" class="logo">
            </router-link>
        </div>
        <div class="header-right">
            <!-- <el-badge :value="2" :max="9" class="notification">
                <i class="el-icon-message"></i>
            </el-badge> -->
            <el-dropdown @command="dropDownHandler">
                <span class="el-dropdown-link profile-options">
                    <el-avatar :src="user.avatar || 'avatar-default.webp'"></el-avatar>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{action: 'profile'}">
                        <i class="el-icon-setting"></i> Profile setting
                    </el-dropdown-item>
                    <el-dropdown-item :command="{action: 'upgrade'}">
                        <i class="el-icon-unlock"></i> Upgrade plan
                    </el-dropdown-item>
                    <el-dropdown-item :command="{action: 'logout'}">
                        <i class="el-icon-refresh-left"></i> Logout
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
    computed: {
        ...mapState('auth', ['user']),
    },
    methods: {
        dropDownHandler({action}) {
            if (action === 'logout') this.$store.dispatch('auth/logout')
            if (action === 'profile') this.$router.push('/profile')
        }
    }    
}
</script>

<style lang="scss" scoped>
.header-right {
    padding-right: 50px !important;
}
.header{
    z-index: 2;
    width: 100vw;
    position: fixed;
    top: 0;
    display: grid;
    grid-template-columns: 1fr 20fr;
    max-height: 70px;
    justify-content: center;
    align-content: center;
    box-shadow: 0px 0px 10px rgba(37,41,45,0.1);
    background-color: #fff;

    &-left{
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    &-right{
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        align-items: center;
    }
}

.profile-options {
    cursor: pointer;
}
.notification{
    font-size: 30px;
    margin-right: 15px;
    color: #63686F;
}

.avatar{
    width: 50px;
    height: auto;
}

.logo{
    width: 50px;
    height: auto;
}
</style>