var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dropdown',{staticClass:"custom-el-dropdown",on:{"command":_vm.onAnswer}},[(
      (_vm.question.warning && !_vm.showAnswers) ||
      (_vm.showAnswers && !_vm.question.isCorrect)
    )?_c('el-button',{attrs:{"type":"danger","circle":"","size":"small"}},[_vm._v(" "+_vm._s(_vm.question.name)+" ")]):(
      (_vm.ans && !_vm.showAnswers) || (_vm.showAnswers && _vm.question.isCorrect)
    )?_c('el-button',{attrs:{"type":"primary","circle":"","size":"small"}},[_vm._v(" "+_vm._s(_vm.question.name)+" ")]):_c('el-button',{attrs:{"type":"info","circle":"","size":"small"}},[_vm._v(" "+_vm._s(_vm.question.name)+" ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.question.answers),function(answer){return _c('el-dropdown-item',{key:("answer-" + (answer.id)),attrs:{"command":{ question: _vm.question, answer: answer, pageIndex: 0 }}},[_c('b',{class:{ selected: answer.name === _vm.question.answer }},[_vm._v(" "+_vm._s(answer.name)+" ")]),(_vm.showAnswers && answer.correct)?_c('span',[_c('i',{staticClass:"el-icon-back"}),_vm._v(" Đáp án đúng ")]):(
          _vm.showAnswers &&
          answer.name === _vm.question.answered &&
          answer.name !== _vm.question.correctAnswer
        )?_c('span',[_c('i',{staticClass:"el-icon-back"}),_vm._v(" Đáp án bạn đã chọn ")]):_vm._e()])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }