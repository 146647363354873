<template>
    <div class="class-box">
        <div class="class-info-item id-col">
            
            <p class="highlight">#{{ feedback.id }}</p>
        </div>

        <div class="tag-type">
            <el-tag
                v-if="isResolved"
                class="tag-type__item"
                effect="success">
                <i class="el-icon-check"></i> Đã xử lý
            </el-tag>
            <el-tag
                v-else
                class="tag-type__item"
                effect="danger">
                <i class="el-icon-timer"></i>
                 Chưa xử lý 

            </el-tag>
            <el-tag effect="success">
                {{ feedback.type  }}
            </el-tag>
        </div>
        <div class="class-info-item second-col">
            <el-popover
                placement="bottom"
                width="400"
                trigger="hover">
                <p class="content-feedback">
                    {{ feedback.text }}
                </p>
                <p class="highlight"  slot="reference"><i class="el-icon-chat-square"></i>
                     {{ limitTextWidth(feedback.text, 600, "16px Arial") }}
                    </p>

            </el-popover>

        </div>
        <div class="class-info-item second-col">
            <p class="last-edit"><b>Tạo lúc:</b> {{ feedback.created_at | updatedAt }}</p>
        </div>
        <div class="col-3">
        
                <el-dropdown class="custom-dropdown">
                    <span class="link to-exam"><i class="el-icon-position"></i> 
                        Đi tới bài thi 
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>
                            <router-link :to="`/builder/${feedback.exam.id}`" class="link to-exam">
                            <i class="el-icon-right" ></i>
                                Đi tới trang sửa đề thi
                            </router-link> 
                        </el-dropdown-item>
                        <el-dropdown-item>
                            <a :href="`${domain}/lam-bai/${feedback.exam.slug}`" target="_blank" class="link to-exam">
                                <i class="el-icon-right" ></i>

                                Đi tới trang làm bài
                            </a> 
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                     <!-- <router-link :to="`/builder/${feedback.exam.id}`" class="link to-exam">
                     <i class="el-icon-right" ></i>
                        Đi tới trang sửa đề thi
                    </router-link> 
                    <br />
                    <a :href="`${domain}/lam-bai/${feedback.exam.slug}`" target="_blank" class="link to-exam">
                        <i class="el-icon-right" ></i>

                        Đi tới trang làm bài
                    </a>  -->
            <!-- <router-link :to="`/builder/${feedback.exam.id}`" class="link to-exam">
                <i class="el-icon-position"></i> 
                Đi tới bài thi 
            </router-link> -->
        </div>
        <el-dropdown @command="command" class="custom-dropdown">
            <el-button type="primary">
                Thao tác
                <i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ type: 'confirmUpdate', data: { feedback } }">
                    <span v-if="!isResolved">
                        <i class="el-icon-check"></i>  Hoàn tất xử lý feedback 
                    </span> 
                    <span v-else>
                        <i class="el-icon-timer"></i>  Đánh dấu là chưa xử lý
                    </span>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>
<script>
import moment from 'moment'
const domain = process.env.VUE_APP_URL

export default {
    props: ['feedback'],

    data() {
        return {
            domain,
            initStatus: false,
        }
    },

    computed: {
        isResolved() {
            return this.feedback.status === 'resolved';
        }
    },
    methods: {
        command({type, data}) {
            console.log(type)
            if (type === 'confirmUpdate') this.$emit('openConfirmBox', data)
        },
        handleChangeStatus() {

        },
        getTextWidth(text, font) {
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            context.font = font; 
            return context.measureText(text).width; 
        },
        limitTextWidth(text, maxWidth, font) {
            let currentText = text;
            
            if (this.getTextWidth(currentText, font) <= maxWidth) {
                return currentText; // Trả về chuỗi gốc nếu không vượt quá giới hạn
            }

            while (this.getTextWidth(currentText + '...', font) > maxWidth) {
                currentText = currentText.slice(0, -1); // Cắt bớt ký tự cuối
            }

            return currentText + '...'; // Thêm dấu '...' khi chuỗi bị cắt ngắn
        }
    },
    filters: {
        updatedAt(time) {
            return time ? moment(time).locale("vi").fromNow() : 'Unknow'
        }
    }
}
</script>
<style lang="scss" scoped>
.content-feedback {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
}
.id-col {
    width: 50px !important;
}
.last-edit {
    font-size: 15px;
    color: #63686F;
}
.first-col{
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.link{
    text-decoration: none;
    color: #899372;
    font-size: 14px;
}
.class-info-item{
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;

}
.class-name{
    font-weight: bold;
}
.link:hover{
    text-decoration: none;
    color: #44ce6f;
}
.custom-dropdown{
}

.to-exam {
    cursor: pointer;
    margin-right: 20px;
}
.col-3 {
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.class{
    &-icon{
        width: 100px;
        height: auto;
    }
    &-box{
        border: none;
        box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
        background-color: #fff;
        background-clip: border-box;
        border-radius: .25rem;
        padding: 10px 10px 10px 10px;
        cursor: pointer;
        display: grid;
        grid-template-columns: 0.5fr 1.5fr 4fr 1fr 1fr 2fr;
        margin-top: 15px;
        align-items: inherit;
        justify-items: center;
    }

    &-box:hover{
        box-shadow: 0px 2px 5px rgba(0,0,0,0.15);
        transition: 0.3s;
    }
}

.ic{
    font-size: 20px;
    margin-right: 10px;   
}
.tag-type {
    width: 180px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    &__item {
        margin-right: 1em;
    }
}
.highlight{
    padding: 0 1em;
    font-size: 14px !important;
}

.custom-link-item{
    text-decoration: none;
    color: #63686F;
}

</style>