<template>
  <el-dropdown class="custom-el-dropdown" @command="onAnswer">
    <el-button
      v-if="
        (question.warning && !showAnswers) ||
        (showAnswers && !question.isCorrect)
      "
      type="danger"
      circle
      size="small"
    >
      {{ question.name }}
    </el-button>
    <el-button
      v-else-if="
        (ans && !showAnswers) || (showAnswers && question.isCorrect)
      "
      type="primary"
      circle
      size="small"
    >
      {{ question.name }}
    </el-button>
    <el-button v-else type="info" circle size="small">
      {{ question.name }}
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :command="{ question, answer, pageIndex: 0 }"
        v-for="answer in question.answers"
        :key="`answer-${answer.id}`"
      >
        <b :class="{ selected: answer.name === question.answer }">
          {{ answer.name }}
        </b>
        <span v-if="showAnswers && answer.correct">
          <i class="el-icon-back"></i> Đáp án đúng
        </span>
        <span
          v-else-if="
            showAnswers &&
            answer.name === question.answered &&
            answer.name !== question.correctAnswer
          "
        >
          <i class="el-icon-back"></i> Đáp án bạn đã chọn
        </span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "PdfQuestion",
  props: ["question", "showAnswers"],
  data: () => ({
    ans: null
  }),
  methods: {
    onAnswer(command) {
      this.ans = command.answer.name
      this.$store.commit("exam/SET_ANSWER", {
        question: command.question,
        answer: this.ans,
        pIndex: command.pageIndex,
      });
    },
  }
};
</script>

<style scoped>
  .bold {
    font-weight: bold;
    color: #67c23a;
  }
  .selected {
    font-weight: bold;
    color: #67c23a;
  }
</style>
