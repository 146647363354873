<template>
    <div v-if="mbti" class="container">
        <div class="page">
            <h1 class="title">
              <p class="your-group">Bạn là</p>
              {{ mbti.name  }}
            </h1>
            <div class="background" :style="{ backgroundImage: `url(${mbti.avatar})` }">

            </div>
            <section v-html="mbti.details">

            </section>
            <div class="btn-group">
                <el-button
                @click="$router.push(`/`)"
                type="primary"
                icon="el-icon-position"
                plain
                >
                Về trang chủ
            </el-button>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState("exam", ["mbti"]),
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .your-group{
    font-size: 18px;
    color: gray;
  }

  h2 {
    line-height: 50px !important;
  }
  
  .btn-group{
    display: flex;
    justify-content: center;
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  .title{
    text-align: center;
    color: #67c23a;
    padding-bottom: 30px;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606266;
  }

  .page{
    width: 70%;
    margin-top: 1em;
    box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
    background-color: #fff;
    min-height: 95vh;
    margin: 8.5vh 0 8vh 0;
    padding: 4vh 4vw 4vh 4vw;
  }

  .background {
    background-image: url(https://www.topcv.vn/images/mbti/web/infj.png?v=2);
    min-height: 40vh;
    background-position: top;
    background-size: cover;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
  .page {
    width: 100%;
    box-shadow: 0px 0px 10px rgba(37, 41, 45, 0.1);
    background-color: #fff;
    min-height: 95vh;
    margin: unset;
    padding: 5%;
    padding-top: unset;
  }
}
</style>
