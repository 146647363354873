<template>
  <div class="action">
    <el-button class="save-btn" @click="openSetting"
      >Cài đặt <i class="el-icon-setting"></i
    ></el-button>
    <router-link
      :to="{ name: 'Exam', params: { id: $route.params.id } }"
      target="_blank"
    >
      <el-button>Xem trước <i class="el-icon-view"></i></el-button>
    </router-link>
    <!-- <Settings
      :visible="openSetting"
      @close="openSetting = false"
      :setting="exam.setting"
      :shareLink="exam.share_link"
      :exam="exam"
    /> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
// import Settings from "@/components/builder/Settings";

export default {
  // components: { Settings },
  computed: {
    ...mapState("builder", ["exam"]),
  },
  methods: {
    openSetting() {
      this.$emit('open-setting', true)
    }
  },

  beforeDestroy() {
    this.$store.commit('builder/SET_EXAM', null)
  }
};
</script>

<style lang="scss" scoped>
.action {
  
  display: flex;
  justify-content: flex-end;
  padding-right: 3.5em;
  align-items: baseline;
}
.save-btn {
  margin-right: 5px;
}
.config {
  &-item {
    margin: 1em 0.5em 1em 1em;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    border: thin solid #ededed;
    padding: 12px 16px;
    color: #606266;
  }
  &-time {
    display: flex;
    flex-direction: column;
  }
  &-time-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-time-input {
    margin-top: 10px;
  }
}
</style>

<style>
.config-time-input .el-input-number {
  width: 100%;
}
</style>