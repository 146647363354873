import { 
  getCategories, deleteCategory, getCategoryAsOptions,
  createCategory, updateCategory, restoreCategory
 } from '@/api/category.js'
import { message } from '@/utils/message.js'

const state = {
  loading: false,
  categories: {},
  pagination: {
    page: 1,
    lastPage: 1,
    total: 0,
    perPage: 10
  },
  sort: {
    type: 'default',
    name: 'Tên'
  },
  options: [],
  validate: []
}

// getters
const getters = {}

// actions
const actions = {
  async getCategories({commit, state}) {
    commit('UPDATE_LOADING', true)
    try {
      const {data} = await getCategories(state.pagination.page, state.sort.type, state.keyword)
      commit('UPDATE_CATEGORIES', data)
    } catch ({response}) {
      message(response.data.message, 'error')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },

  async restoreCategory({ commit }, id) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await restoreCategory(id)
      commit('UPDATE_CATEGORIES', data)
      message('Restore successfully.', 'success')
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async deleteCategory({ commit }, slug) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await deleteCategory(slug)
      commit('UPDATE_CATEGORIES', data)
      message('Delete successfully.', 'success')
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async getCategoryAsOptions({ commit }) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await getCategoryAsOptions()
      commit('UPDATE_OPTIONS', data)
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
  async update({ commit }, category) { 
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await updateCategory(category.id, category)
      commit('UPDATE_CATEGORIES', data)
      message('Update successfully.', 'success')
    } catch (e) {
      console.log(e)
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },

  async create({ commit }, reqData) {
    commit('UPDATE_LOADING', true)
    try {
      const { data } = await createCategory(reqData)
      commit('UPDATE_CATEGORIES', data)
    } catch ({ response }) {
      message(response.data.message, 'warning')
    } finally {
      commit('UPDATE_LOADING', false)
    }
  },
}

// mutations

const mutations = {
  UPDATE_LOADING(state, status){
    state.loading = status
  },
  UPDATE_CATEGORIES(state, categories) {
    state.pagination.page = categories.current_page
    state.pagination.total = categories.total
    state.pagination.lastPage = categories.last_page
    state.categories = categories.data
  },
  UPDATE_CURRENT_PAGE(state, page) {
    state.pagination.page = page
  },
  RESET_PAGINATE() {
    state.pagination = {
      page: 1,
      lastPage: 1,
      total: 0,
      perPage: 10
    }
  },
  UPDATE_SORT_TYPE(state, data) {
    state.sort = data
  },
  UPDATE_SEARCH_KEYWORD(state, keyword) {
    state.keyword = keyword
  },
  UPDATE_OPTIONS(state, options) {
    state.options = options
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}