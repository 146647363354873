<template>
  <section>
    <div v-loading="loading">
      <Header />
      <section class="main">
        <Menu />
        <div class="workspace"  element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="workspace" >
            <div class="tools">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total"
                :current-page="pagination.page"
                :page-size="10"
                @current-change="onChangeCurrentPage"
              >
              </el-pagination>

              <div class="tools-right">
                <div class="search">
                  <el-input v-model="keyword" placeholder="Nhập từ khoá">
                    <i
                      v-if="keyword"
                      class="el-icon-circle-close el-input__icon"
                      slot="suffix"
                      @click="keyword = null"
                    >
                    </i>
                    <i
                      v-else
                      class="el-icon-search el-input__icon"
                      slot="suffix"
                    >
                    </i>
                  </el-input>
                </div>
                <div class="filters">
                  <el-dropdown class="active filter" @command="sortName">
                    <el-button>
                      {{ sort.name
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        icon="el-icon-sort-up"
                        :command="{ type: 'asc', name: 'Tăng dần' }"
                      >
                      Tăng dần
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="el-icon-sort-down"
                        :command="{ type: 'desc', name: 'Giảm dần' }"
                      >
                        Giảm dần
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    class="filter"
                    plain
                    @click="opennewCategory"
                  >
                    Thêm thể loại
                  </el-button>
                </div>
              </div>
            </div>
            <div class="class-boxes" v-if="categories.length > 0">
              <CategoryItem
                v-for="(category, index) in categories"
                @openCreateCategory="openCreateCategory"
                @openDeleteBox="openDeleteBox"
                @openEdit="openEditForm"
                @toggleNewExam="toggleNewExam"
                @restoreCategory="restoreCategory"
                :category="category"
                :key="`class-${index}`"
              />
            </div>
            <div class="empty" v-else>
              <div class="empty-wrapper">
                <i class="el-icon-folder-opened empty-icon"></i>
                <p>
                  You don't have any category. Please
                  <b class="create-link" @click="newCategory = true"
                    >create new.</b
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewCategory
        :dialogVisible="newCategory"
        @close-dialog="newCategory = false"
      />
      <EditCategory
        v-if="showEditCategory"
        :dialogVisible="showEditCategory"
        @close-dialog="handleCloseDialog"
        :category="categoryData"
      />
      <DeletePopup
        :open="openDelete"
        :payload="selectedDelete"
        @delete="deleteCategory"
        @closeDeleteBox="openDelete = false"
      >
      <span>Are you sure to delete this category?</span>
      </DeletePopup>
      <NewExam
        :openNewExam="openNewExam"
        @toggleNewExam="toggleNewExam"
      />
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import Menu from "@/components/commons/Menu.vue"
import Header from "@/components/commons/Header.vue"
import CategoryItem from "@/components/class/CategoryItem.vue"
import NewCategory from "@/components/class/NewCategory.vue"
import EditCategory from "@/components/class/EditCategory.vue"

import DeletePopup from "@/components/commons/DeletePopup.vue"
import NewExam from "@/components/class/NewExam.vue"

export default {
  components: {
    Menu,
    Header,
    CategoryItem,
    NewCategory,
    DeletePopup,
    NewExam,
    EditCategory,
  },
  computed: {
    ...mapState("category", ["categories", "pagination", "loading", "sort"]),
  },
  beforeMount() {
    this.$store.commit("category/RESET_PAGINATE")
  },
  mounted() {
    this.$store.dispatch("category/getCategories")
  },
  data() {
    return {
      newCategory: false,
      keyword: null,
      delayTimer: null,
      openDelete: false,
      selectedDelete: {},
      category: {},
      categoryData: {},
      showEditCategory: false,
      openNewExam: false
    };
  },
  methods: {
    handleCloseDialog() {
      this.showEditCategory = false;
    },
    toggleNewExam() {
      this.openNewExam = !this.openNewExam;
    },

    openEditForm(data) {
      this.categoryData = data.category;

      this.showEditCategory = true;
    },

    opennewCategory() {
      this.newCategory = true
    },
    async onChangeCurrentPage(page) {
      await this.$store.commit("category/UPDATE_CURRENT_PAGE", page)
      this.$store.dispatch("category/getCategories");
    },
    async sortName(data) {
      await this.$store.commit("category/UPDATE_SORT_TYPE", data)
      this.$store.dispatch("category/getCategories");
    },
    openCreateCategory({ category }) {
      this.category = category
    },
    openDeleteBox({ category }) {
      this.selectedDelete = category

      this.openDelete = true
    },

    restoreCategory(data) {
      this.$store.dispatch("category/restoreCategory", data.category.id)
    },
    deleteCategory({ slug }) {
      this.$store.dispatch("category/deleteCategory", slug)
      this.openDelete = false
    },
  },
  watch: {
    async keyword(k) {
      if (k) {
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(async () => {
          await this.$store.commit("category/UPDATE_SEARCH_KEYWORD", k)
          this.$store.dispatch("category/getCategories")
        }, 300);
      } else {
        await this.$store.commit("category/UPDATE_SEARCH_KEYWORD", null)
        this.$store.dispatch("category/getCategories")
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-height: 95vh;
  display: grid;
  grid-template-columns: 1fr 20fr;
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;

  &-icon {
    font-size: 50px;
    color: #c0c4cc;
    margin-bottom: 20px;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #5e6d82;
  }
}
.create-link {
  color: #409eff;
  cursor: pointer;
}

.filters {
  display: flex;
}

.filter {
  margin-left: 10px;
}

.ic {
  font-size: 20px;
  margin-right: 10px;
  color: #c0c4cc;
}

.workspace {
  padding: 15px;
    padding-top: 40px;
    width: calc(100vw - 120px);
    min-height: 30vh;
    padding-left: 40px;
}

.class-boxes {
  margin-bottom: 15px;
}
</style>