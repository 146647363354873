import {
    getAll,
    updateStatus
  } from '@/api/feedback.js'
// import { message } from '@/utils/message.js'
import Vue from 'vue'
  
  const state = {
    exam: {},
    examPreview: {},
    time: {},
    loading: true,
    done: false,
    result: {},
    showAnswers: false,
    submissions: [],
    pagination: {
      page: 1,
      lastPage: 1,
      total: 0,
      perPage: 10
    },
    filter: {
      sort: 'desc',
      filters: [
        { field: "status", value: "all" },
        { field: "type", value: "all" },
      ],
      keyword: null,
      mark: 5,
    },
    feedbacks: []
  }
  
  // getters
  const getters = {}
  
  // actions
  const actions = {
    async getAll({ commit }) {
      commit('SET_LOADING', true)
      try {

        const response = await getAll({ 
          page: state.pagination.page, 
          filters: state.filter.filters, 
        })
        if(response) {
            commit('SET_FEEDBACKS', response.data)
        }
      } catch (e) {
        console.log(e)
      } finally {
        commit('SET_LOADING', false)
      }
    },

    async updateStatus({commit }, value) {
      try {
          commit('SET_LOADING', true);

          await updateStatus(value.id, { status: value.data }).then(async () => {
            const response = await getAll({ 
              page: state.pagination.page, 
              filters: state.filter.filters, 
            })
            if(response) {
                commit('SET_FEEDBACKS', response.data)
            }
          })
      } catch (e) {
        commit('SET_LOADING', false)
      } finally {
        commit('SET_LOADING', false)
      }
    }
  
  }
  
  // mutations
  
  const mutations = {
    SET_FEEDBACKS(state, { feedbacks }) {
      state.feedbacks = feedbacks
      state.pagination.page = feedbacks.current_page
      state.pagination.total = feedbacks.total
      state.pagination.lastPage = feedbacks.last_page
      state.pagination.perPage = feedbacks.per_page

    },
    SET_EXAM_PREVIEW(state, exam) {
      state.examPreview = exam
    },

  
    SET_LOADING(state, loading) {
      if (loading) {
        state.loading = loading
      } else {
        state.loading = !state.loading
      }
    },
  
    SET_ANSWER({ exam }, { question, answer, pIndex }) {
      let qIndex = exam.pages[pIndex].questions.findIndex(q => q.id === question.id)
      exam.pages[pIndex].questions[qIndex].answer = answer
    },
  
    SET_WARNING_ANSWER({ exam }, { pIndex, qIndex, status }) {
      Vue.set(exam.pages[pIndex].questions[qIndex], 'warning', status)
    },
  
    SET_DONE(state, status) {
      state.done = status
    },
  
    SET_RESULT(state, { score, correctAnswers, totalQuestion, exam }) {
      state.result.score = score
      state.result.correctAnswers = correctAnswers
      state.result.totalQuestion = totalQuestion
      state.exam = exam
    },
  
    SET_SHOW_ANSWERS(state, status) {
      state.showAnswers = status
    },
  
    UPDATE_SUBMISSSIONS(state, { submissions }) {
      state.submissions = submissions.data
      state.pagination.page = submissions.current_page
      state.pagination.total = submissions.total
      state.pagination.lastPage = submissions.last_page
    },
  
    UPDATE_CURRENT_PAGE(state, page) {
      state.pagination.page = page
    },
  
    RESET_PAGINATE(state) {
      state.pagination = {
        page: 1,
        lastPage: 1,
        total: 0,
        perPage: 10
      }
    },
    UPDATE_FILTER_TYPE(state, { field, value }) {
      const index = state.filter.filters.findIndex(filter => filter.field === field);
      
      if (index !== -1) {
          state.filter.filters[index].value = value;
      } else {
          state.filter.filters.push({ field, value });
      }

      console.log(state.filter)
    },
    UPDATE_SEARCH_KEYWORD(state, keyword){
      state.filter.keyword = keyword
    }
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }