<template>
  <div class="menu">

    <ul class="menu-group">
      <li class="menu-group-item" @click="$router.push('/')">
          <el-tooltip  content="Danh mục" placement="right-start">
            <i   :class="`el-icon-tickets ${activeMenu === '/' || activeMenu === 'category' || activeMenu === 'builder' ? 'item-active' : ''}`"></i>
          </el-tooltip>
      </li>
      <li class="menu-group-item" @click="$router.push('/feedbacks')">
          <el-tooltip  content="Phản hồi" placement="right-start">
            <i   :class="`el-icon-chat-square ${activeMenu === 'feedbacks' ? 'item-active' : ''}`"></i>
          </el-tooltip>
      </li>
      <li class="menu-group-item">
          <el-tooltip content="Trợ giúp" placement="right-start">
            <i :class="`el-icon-help  ${activeMenu === 'helps' ? 'item-active' : ''}`"></i>
          </el-tooltip>
      </li>
      <li class="menu-group-item" @click="$router.push('/profile')">
          <el-tooltip content="Profile" placement="right-start">
            <el-badge value="P" class="item" type="warning">
              <i :class="`el-icon-user  ${activeMenu === 'profile' ? 'item-active' : ''}`"></i>
            </el-badge>
          </el-tooltip>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed:  {
    activeMenu() {
    
      const parts = this.$route.fullPath.length > 1 ? this.$route.fullPath.split('/').filter(part => part !== '') : ['/']


      return `${parts[0]}`
    }
  }
}

</script>
<style lang="scss" scoped>
.menu-group{
  list-style: none;
  margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
.menu{
    position: fixed;
    top: 0;
    left: 0;
    /* margin-top: 53px; */
    padding-top: 60px;
    height: 100vh;
    padding-top: 80px;
    width: 60px;
    display: flex;
    justify-content: center;
    border-right: thin solid #EDEDED;
    background-color: #f5f5f5;

    &-group{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        font-size: 30px;

        &-item{
            cursor: pointer;
            width: 100%;
            min-height: 100px;
        }
    }
}
.item-active {
  background: #67c23a;
  color: white;
  padding: 3px;
  border-radius: 5px;
}
.item{
    color: black;
}
</style>