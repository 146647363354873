<template>
  <section>
    <div  v-loading="loading">
      <Header />
      <section class="main">
        <Menu />
        <div class="workspace" element-loading-background="rgba(0, 0, 0, 0.8)">
          <div class="form">
            <div class="form-line">
              <p>Status<i class="required">*</i></p>
              <el-select v-model="status" placeholder="Select">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-line">
              <p>Category <i class="required">*</i></p>
              <el-select
                v-model="categoryId"
                :value="categoryId"
                placeholder="Select"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                >
                </el-option>
              </el-select>
            </div>
            <div class="form-line">
              <p>Title <i class="required">*</i></p>
              <el-input placeholder="Please input" v-model="title"></el-input>
            </div>
            <div class="form-line">
              <p>Meta title <i class="required">*</i></p>
              <el-input
                placeholder="Please input"
                v-model="metaTitle"
              ></el-input>
            </div>
            <div class="form-line">
              <p>Meta description <i class="required">*</i></p>
              <el-input
                placeholder="Please input"
                v-model="metaDescription"
              ></el-input>
            </div>
            <div class="form-line">
              <p>Cover image <i class="required">*</i></p>
              <el-upload
                ref="uploadCover"
                :multiple="false"
                :file-list="fileList"
                :action="`${domain}/api/v1/storage/image/upload`"
                class="upload-demo"
                :on-success="afterUploadSuccess"
                :headers="headers"
                @clearFiles="fileList"
              >
                <el-button size="small" type="default"
                  >Click to upload</el-button
                >
              </el-upload>
            </div>
            <div class="form-line edit-post">
              <p>Content <i class="required">*</i></p>
              <Tiptap @onChangeText="onChangeText" :name="content" v-if="content"/>
            </div>
            <div class="form-submit">
              <el-button type="primary" icon="el-icon-check" @click="done"
                >Done</el-button
              >
              <el-button
                type="default"
                icon="el-icon-s-home"
                @click="$router.push('/')"
              >
                Back to home
              </el-button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import Menu from "@/components/commons/Menu.vue"
import Header from "@/components/commons/Header.vue"
import PostData from '@/components/post/PostData'
import Tiptap from "@/components/builder/Tiptap.vue"
const domain = process.env.VUE_APP_API_URL

export default {
  mixins: [PostData],
  components: {
    Menu,
    Header,
    Tiptap,
  },
  data: () => ({domain: domain}),
  computed: {
    ...mapState("post", ["post", "loading"]),
    ...mapState("category", ['options']),
  },
  async mounted() {
    await this.$store.dispatch("category/getCategoryAsOptions")
    await this.$store.dispatch("post/getPost", this.$route.params.slug)
  },
  methods: {
    onChangeText(content) {
      this.content = content;
    },
    async done() {
      await this.$store.dispatch('post/editPost', {
        status: this.status,
        category_id: this.categoryId,
        title: this.title,
        meta_title: this.metaTitle,
        meta_description: this.metaDescription,
        cover: this.cover,
        post_content: this.content,
        id: this.id
      })
    },
    afterUploadSuccess({url}) {
      this.cover = url
      this.fileList = [{name: url.split('/').pop(), url}]
    }
  },
  watch: {
    post(p) {
      this.id = p.slug
      this.status = p.status
      this.categoryId = p.category_id
      this.title = p.title
      this.metaTitle = p.meta_title
      this.metaDescription = p.meta_description
      this.content = p.content
      this.cover = p.avatar
      this.fileList = [{name: p.avatar.split('/').pop(), url: p.avatar}]
    },
  },
}
</script>

<style lang="scss" scoped>
.main {
  min-height: 95vh;
  display: grid;
  grid-template-columns: 1fr 20fr;
}

.form {
  margin: 50px;
  padding: 50px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  transition: 0.2s;
  color: #606266;
  background-color: #fafafa;

  &-line {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 45px;
  }
  &-submit {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.required {
  color: #f56c6c;
}
</style>

<style lang="scss">
.edit-post {
  .editor__content {
    min-height: 500px !important;
    background-color: white;
    height: 60vh;
    overflow-y: scroll;
  }
}

img {
  max-width: 50%;
}
</style>