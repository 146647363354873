<template>
    <div class="question">
        <div class="title-wrapper">
          <i class="el-icon-check ic-correct-custom" v-if="showAnswers && question.isCorrect"></i>
          <i class="el-icon-close ic-wrong-custom" v-else-if="showAnswers && !question.isCorrect"></i>
          <p class="title" v-html="question.name" :class="{warning: question.warning}"></p>
        </div>
        <div class="answer">
            <el-select v-model="answer" placeholder="Please choice an answer..."
            :disabled="showAnswers"
            :class="{
              'is-wrong': showAnswers && question.answered !== question.correctAnswer
            }"
            >
              <el-option
                v-for="item in question.answers"
                :key="`select-${item.id}`"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
            <div class="correct-select-answer" v-if="!question.isCorrect">
              <i class="el-icon-circle-check ic-correct-select-answer" v-if="showAnswers"></i>
              <p v-html="question.correctAnswer"></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['question', 'showAnswers'],
  data () {
    return {
      answer: this.showAnswers ? this.question.answered : this.question.answer
    }
  },
  watch: {
    answer () {
      this.$emit('onAnswer', {
        question: this.question,
        answer: this.answer
      })
    },
    question (question) {
      this.answer = this.showAnswers ? question.answered : question.answer
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  color: #202124;
  font-size: 16px;
}
.answer{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 50%;
}

.el-radio{
  line-height: 50px;
}

.warning{
  color: #f56c6c;
}

.ic-correct-custom{
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
  color: #67C23A;
}

.ic-wrong-custom{
  margin-right: 5px;
  font-weight: bold;
  font-size: 25px;
  color: #f56c6c;
}

.title-wrapper{
  display: flex;
  align-items: baseline;
}

img {
  max-width: 100%;
}

.correct-select-answer{
  display: flex;
  align-items: baseline;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ic-correct-select-answer{
  font-weight: bold;
  color: #67C23A;
  font-size: 15px;
  margin-right: 5px;
}
</style>

<style>
.is-wrong .el-input.is-disabled .el-input__inner{
  border-color: #f56c6c !important;
  border-color: #f56c6c !important;
  background-color: #fff;
  color: #606266;
}
</style>