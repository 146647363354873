<template>
  <section class="footer">
    <div class="footer-btn" >
      <el-button
        type="primary"
        icon="el-icon-back"
        plain
        v-if="this.currentPage > 0"
        @click="$emit('back')"
      >
        Quay lại
      </el-button>
      <el-button
        type="primary"
        plain
        v-if="pages.length > 1 && this.currentPage < pages.length - 1"
        @click="$emit('next')"
      >
        Tiếp theo <i class="el-icon-arrow-right el-icon-right"></i>
      </el-button>
    </div>
    <el-button
      type="default"
      icon="el-icon-close"
      plain
      v-if="this.currentPage === pages.length - 1 && showClose"
      @click="$emit('close')"
    >
      Đóng
    </el-button>
    <div  v-else-if="this.currentPage === pages.length - 1" class="btn-submit">

        <el-button disabled type="primary" slot="reference" icon="el-icon-check">
          Nộp bài
        </el-button>
    </div>
    <el-button
      @click="$router.push(`/danh-sach-bai-tap/${examCategory.slug}`)"
      type="default"
      icon="el-icon-position"
      plain
      v-else-if="this.currentPage === pages.length - 1 && showAnswers"
    >
      Làm các đề khác
    </el-button>
  </section>
</template>
  
  <script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("exam", ["examCategory", "exam"]),
  },
  data() {
    return {
      drawer: false,
      direction: "btt",
      text: '',
      isFeedBackInvalid: false,
      feedbackType: 'report'
    };
  },
  props: ["pages", "currentPage", "showAnswers", "showClose"],
  methods: {
    handleClose(done){
      this.text = ''
      this.isFeedBackInvalid = false
      done();
    },
    openDrawer(type){
      this.drawer = true
      this.feedbackType = type
    },
    submit() {
      this.$store.dispatch("exam/submit");
    },
    async sendFeedback() {
      if (this.text.length < 10) {
        this.isFeedBackInvalid = true
        return
      }

      await this.$store.dispatch("exam/sendFeedback", {
        examId: this.exam.id,
        text: this.text,
        type: this.feedbackType
      });

      this.text = ''
      this.isFeedBackInvalid = false
      this.drawer = false
    }
  },
};
</script>
  
<style lang="scss">
.feed-back-wrapper{
  padding-left: 20px;
  padding-right: 20px;
}
.warning {
  color: #f56c6c;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 13px;
}
.v-modal {
  background: unset;
  z-index: auto !important;

}
.feedback-input{
  margin-bottom: 20px;
}
.text {
  padding: 0 0 20px 20px;
}
.feedbacks {
  position: fixed;
  z-index: 8 !important;
  bottom: 1vh;
  right: 15vw;
  cursor: pointer;
  background: white;
}
.actionIcon {
  font-size: 30px;
}
.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  &-btn {
    margin-right: 5px;
  }
}
.btn-submit {
  /* left: 51vw; */
  bottom: 0;
  padding: 0.3em;
  z-index: 7;
  background: white;
  width: 100vw !important;
  position: fixed;
  text-align: center;
  box-shadow: 0px 1px 7px 0px #5b615c57;
}

.footer-btn {
  // left: 51vw;
  bottom: 2px;
  z-index: 6;
  position: fixed;

  margin-right: 5px;
  background: white;
  width: 100vw;
  padding: 0.2em;
  text-align: center;
  box-shadow: 0px 1px 7px 0px #5b615c57;
}

@media only screen and (max-width: 600px) {
  .only-pc {
    span {
      display: none;
    }
  }
}
</style>
