<template>
    <div>
        <el-button type="text" @click="dialogVisible = true"></el-button>
        <el-dialog
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
        >
            <slot>
             
            </slot>
            <span slot="title"> 
                <i class="el-icon-warning-outline"></i> Xử lý phản hồi
            </span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="$emit('closeConfirmBox')"> <i class="el-icon-close"></i>  Huỷ</el-button>
            <el-button type="primary" @click="$emit('confirm', payload)"> <i class="el-icon-check"></i>  Xác nhận</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ['open', 'payload'],
    data(){
        return {
            dialogVisible: false
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeConfirmBox')
        }
    },
    watch: {
        open(v){
            this.dialogVisible = v
        }
    }
}
</script>

<style>

.dialog-footer {
    text-align: center;
}
</style>